import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThemeProvider, createTheme } from "@mui/material";
import { CssBaseline } from "@mui/material";
import getDesignTokens from "./utils/theme";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./utils/i18n";

import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";

import Home from "./pages/Home";
import Game from "./pages/Game";
import Bonus from "./pages/Bonus";
import Vip from "./pages/Vip";
import Affiliate from "./pages/Affiliate";
import ProfileWallet from "./pages/ProfileWallet";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Rules from "./pages/Rules";
import Faq from "./pages/Faq";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ContactUs from "./pages/ContactUs";
import Language from "./pages/Language";
import Backdrop from "./components/common/Backdrop";
import HowToJoin from "./pages/HowToJoin";
import HowToDeposit from "./pages/HowToDeposit";
import HowToWithdraw from "./pages/HowToWithdraw";
import ResponsibleGaming from "./pages/ResponsibleGaming";
import KYCVerification from "./pages/KYCVerification";
import AntiScam from "./pages/AntiScam";
import Referral from "./pages/Referral";
import ModalDialog from "./components/common/ModalDialog";
import { current } from "@reduxjs/toolkit";
import * as Constant from "./features/constant";
import PromoCenter from "./pages/PromoCenter";
function App() {
  const theme = createTheme(getDesignTokens());

  setTimeout(() => {
    var statedata = localStorage.getItem("persist:root");
    if (statedata.includes("\\\"isOpen\\\":true")) {
      var newstatedata = statedata.replace("\\\"isOpen\\\":true", "\\\"isOpen\\\":false");
      localStorage.setItem("persist:root", newstatedata);
      window.location.reload();
    }
  }, 1000);

  if (process.env.REACT_APP_LANG_PATH_PARAM) {
    let pathname = window.location.pathname;
    let searchParams = window.location.search || "";
    if (pathname == "/") {
      pathname = "";
    }
    let i18nProcessing = localStorage.getItem("i18n_processing");
    // let currentLang = localStorage.getItem("language") || "bd";
    // let pathLang = "";

    // let langParam = Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY]["en"];
    // if (pathname.startsWith("/" + langParam + "/")) {
    //   pathLang = "en";
    // }
    // else {
    //   if (process.env.REACT_APP_COUNTRY == "PH") {
    //     langParam = Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY]["ph"];
    //     if (pathname.startsWith("/" + langParam + "/")) {
    //       pathLang = "ph";
    //     }
    //   } else if (process.env.REACT_APP_COUNTRY == "BD") {
    //     langParam = Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY]["bd"];
    //     if (pathname.startsWith("/" + langParam + "/")) {
    //       pathLang = "bd";
    //     }
    //   }
    // }
    let currentLang = localStorage.getItem("language") || "en";
    let pathLang = "";

    let langParam = Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY]["en"];
    if (pathname.startsWith("/" + langParam + "/") || pathname.endsWith("/" + langParam)) {
      pathLang = "en";
    }
    else {
      if (process.env.REACT_APP_COUNTRY == "MY") {
        langParam = Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY]["my"];
        if (pathname.startsWith("/" + langParam + "/") || pathname.endsWith("/" + langParam)) {
          pathLang = "my";
        }
      }
    }

    if (i18nProcessing == "1") {
      localStorage.setItem("i18n_processing", "0");
    }
    else {
      if (!pathLang) {
        pathname = pathname.replaceAll(Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY][currentLang], "");
        window.location.href = "/" + Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY][currentLang] +
          (pathname ? pathname : "/") + searchParams;
      }
      else {
        if (pathLang != currentLang) {
          localStorage.setItem("language", pathLang);
          window.location.reload();
        }
      }
    }
  }

  if (window.location.pathname.endsWith("//")) {
    window.location.href = window.location.pathname.replaceAll("//", "");
  }

  function getRoutePath(path) {
    if (process.env.REACT_APP_LANG_PATH_PARAM) {
      return "/:lang/" + path;
    }
    else {
      return path;
    }
  }

  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScrollToTop />
          <ModalDialog />
          <Suspense fallback={<Backdrop />}>
            <Routes>
              <Route element={<Layout />}>
                <Route path="*" element={<Home />} />
                <Route index element={<Home />} />
                <Route path={getRoutePath("game/:gameCategoryCode")} exact element={<Game />} />
                <Route path={getRoutePath("game/:gameCategoryCode/:gameProviderCode")} exact element={<Game />} />
                <Route path={getRoutePath("promotion")} exact element={<Bonus />} />
                <Route path={getRoutePath("promocenter")} exact element={<PromoCenter />} />
                <Route path={getRoutePath("affiliate")} exact element={<Affiliate />} />
                <Route path={getRoutePath("register")} exact element={<Register />} />
                <Route path={getRoutePath("login")} exact element={<Login />} />
                <Route path={getRoutePath("vip")} exact element={<Vip />} />
                <Route path={getRoutePath("about")} exact element={<About />} />
                <Route path={getRoutePath("terms")} exact element={<Terms />} />
                <Route path={getRoutePath("rules")} exact element={<Rules />} />
                <Route path={getRoutePath("faq")} exact element={<Faq />} />
                <Route path={getRoutePath("contactus")} exact element={<ContactUs />} />
                <Route path={getRoutePath("language")} exact element={<Language />} />
                <Route path={getRoutePath("howToJoin")} exact element={<HowToJoin />} />
                <Route path={getRoutePath("howToDeposit")} exact element={<HowToDeposit />} />
                <Route path={getRoutePath("howToWithdraw")} exact element={<HowToWithdraw />} />
                <Route path={getRoutePath("responsibleGaming")} exact element={<ResponsibleGaming />} />
                <Route path={getRoutePath("kycVerification")} exact element={<KYCVerification />} />
                <Route path={getRoutePath("antiScam")} exact element={<AntiScam />} />
                <Route path={getRoutePath("forgotPassword")} exact element={<ForgotPassword />} />
                <Route path={getRoutePath("resetPassword")} exact element={<ResetPassword />} />
                <Route element={<RequireAuth />}>
                  <Route path={getRoutePath("profileWallet/:tab?")} exact element={<ProfileWallet />} />
                </Route>
                <Route path={getRoutePath("referral")} exact element={<Referral />} />
              </Route>
            </Routes>
          </Suspense>
        </ThemeProvider>
      </LocalizationProvider>
    </I18nextProvider>
  );
}

export default App;
