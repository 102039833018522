import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import Image from "./common/Image";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  useGetGameCategoryQuery,
  useGetGameProviderQuery,
} from "../features/api/gameApiSlice";
import * as Constant from "../features/constant";

const certificationIcon = [
  process.env.REACT_APP_FOOTER + "/certificate1.png",
  process.env.REACT_APP_FOOTER + "/certificate2.png",
  process.env.REACT_APP_FOOTER + "/certificate3.png",
  process.env.REACT_APP_FOOTER + "/certificate4.png",
  process.env.REACT_APP_FOOTER + "/certificate5.png",
];

const paymentIcon = [
  process.env.REACT_APP_FOOTER + "/payment1.png",
  process.env.REACT_APP_FOOTER + "/payment2.png",
  process.env.REACT_APP_FOOTER + "/payment3.png",
  process.env.REACT_APP_FOOTER + "/payment4.png",
  process.env.REACT_APP_FOOTER + "/payment5.png",
  process.env.REACT_APP_FOOTER + "/payment6.png",
  process.env.REACT_APP_FOOTER + "/payment7.png",
];

const securityIcon = [process.env.REACT_APP_FOOTER + "/security1.png"];

const responsibleGamingIcon = [
  process.env.REACT_APP_FOOTER + "/responsible1.png",
  process.env.REACT_APP_FOOTER + "/responsible2.png",
  process.env.REACT_APP_FOOTER + "/responsible3.png",
];

const Footer = ({ background, contactDetails }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    data: gameCategory,
    isLoading: isGameCategoryLoading,
    isSuccess: isGameCategorySuccess,
  } = useGetGameCategoryQuery();

  const {
    data: gameProvider,
    isLoading: isGameProviderLoading,
    isSuccess: isGameProviderSuccess,
  } = useGetGameProviderQuery({ category: gameCategory?.result[0]?.code });

  const links = [
    i18n.t("footer.home"),
    i18n.t("footer.aboutus"),
    // i18n.t("footer.game"),
    i18n.t("footer.bettingrules"),
    i18n.t("footer.t&c"),
    // i18n.t("footer.promotion"),
    i18n.t("footer.faq"),
  ];

  const links3 = [
    i18n.t("footer.responsiblegaming"),
    i18n.t("footer.kycverification"),
    i18n.t("footer.antiscam"),
  ];

  return (
    <>
      <Box>
        <Image src={process.env.REACT_APP_FOOTER + "/footer2.jpg"} />
        <Image src={process.env.REACT_APP_FOOTER + "/footer.jpg"} />
      </Box>
    </>
  );
};

Footer.propTypes = {
  background: PropTypes.string,
  contactDetails: PropTypes.object,
};

Footer.defaultProps = {
  background: "",
  contactDetails: {},
};

export default Footer;
