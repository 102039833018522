import React, {useState} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout, AccountCircle } from "@mui/icons-material";
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import i18n from "i18next";
import { selectCurrentToken } from "../../features/slices/authSlice";
import { logout } from "../../features/slices/authSlice";
import * as Constant from "../../features/constant";
import { useGetProfileQuery } from "../../features/api/userApiSlice";

import Image from "../common/Image";
import DropdownMenu from "../common/DropdownMenu";
import Drawer from "../common/Drawer";
import Backdrop from "../common/Backdrop";

import { useTranslation } from "react-i18next";
import LanguageDropdown from "../common/LanguageDropdown";
import {useGetLanguagesQuery} from "../../features/api/publicApiSlice";


const MobileHeader = ({ background }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);
  const domain = window.location.origin;
  const apkUrl = domain.replace("/\\/(en|bn)-bd\\//, \"/\"", "/") + "/ilt8.apk";
  const [showPopup, setShowPopup] = useState(true);
  const logoutProfileItems = [
    {
      label: i18n.t("walletcard.logout"),
      icon: <Logout sx={{ height: 20 }} />,
    },
  ];
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleButtonClick = () => {
    window.open(apkUrl, "_blank");
  };
  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isLoading: isLanguagesLoading,
    isSuccess: isLanguagesSuccess,
  } = useGetLanguagesQuery({ prefetch: true });

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  if (isUserFetching || isUserLoading) {
    return <Backdrop />;
  }

  const handleLogoutOnClick = (item) => {
    if (item?.label === i18n.t("walletcard.logout")) {
      dispatch(logout());
      navigate(Constant.getPath("/"));
    }
  };

  return (
      <>
        <AppBar position="fixed" sx={{marginBottom:'10px'}}>
          <Toolbar
              sx={{
                background: theme.palette.background.secondaryPaper,
                height: 0,
              }}
          >
            <Grid container justifyContent="space-between" >
              {/*   <Grid item xs={4} alignSelf="center">
              <Drawer user={user?.result} />
            </Grid>*/}
              <Grid item xs={3} textAlign="left" sx={{maxWidth: "13%", display: 'flex', alignItems: 'center'}}>
                <Box sx={{display: "flex"}}>
                  <Image
                      src={process.env.REACT_APP_LOGO}
                      onClick={() => navigate(Constant.getPath("/"))}
                      alt={Constant.ALT_TAG_LOGO}
                  />
                </Box>
              </Grid>
              {token && (
                  <Grid item xs={3} alignSelf="right" textAlign="right">
                    <Button
                        variant="contained"
                        sx={{
                          width: "60px",
                          height: "30px",
                          borderRadius: '3px', // Add border-radius for curved corners
                          background: theme.palette.button.primary.main,
                          backgroundImage: 'url(/assets/img/yellow_big.png)',
                          padding: 0,
                          fontSize: "12px",
                          backgroundSize: "100% 100%",
                          position: "absolute",
                          marginRight: "125px",
                          marginTop: "3px",
                          right: "0"
                        }}
                        disableRipple
                        onClick={() =>
                            navigate(Constant.getPath("/profileWallet"), {state: {screen: "Deposit"}})
                        }
                    >
                      {t("walletcard.deposit")}
                    </Button>
                    <Typography sx={{
                      color: "white",
                      float: "right",
                      marginTop: "8px",
                      //paddingRight: "5px",
                      position: "absolute",
                      fontSize: "14px",
                    }}>
                      {user?.result?.currentCredit}
                    </Typography>
                    <DropdownMenu
                        sxMenu={{
                          mt: "40px",
                        }}
                        items={logoutProfileItems}
                        handleOnClickEvent={handleLogoutOnClick}
                        icon={true}
                    >
                      <AccountCircle/>
                    </DropdownMenu>

                  </Grid>
              )}
              {/* Mobile view login & register (below banner) */}
              {!token && (
                  <Grid item xs={7} textAlign="right" sx={{maxWidth: "75%"}}>
                    <Box sx={{display: "flex"}}>
                      <Image
                          width={25}
                          height={25}
                          sx={{
                            mr: "10px"
                          }}
                          src={process.env.REACT_APP_HEADER_LOGO + "/PROMOTION.png"}
                          onClick={() => {
                            navigate(Constant.getPath("/promotion"));
                          }}
                      />
                      <Button
                          variant="contained"
                          sx={{
                            //background:  theme.palette.button.secondary.main,
                            backgroundImage: 'url(/assets/img/f39200_button_big.png)',
                            width: "40%", // Adjust the width as needed and consider the margin
                            marginRight: "8px", // Add margin to create a gap
                            borderRadius: '3px', // Add border-radius for curved corners
                            padding: 0,
                            fontSize: "12px",
                            backgroundSize: "100% 100%",

                          }}
                          disableRipple
                          onClick={() =>
                              navigate(Constant.getPath("/login"))
                          }
                      >
                        {t("home.login")}
                      </Button>
                      <Button
                          variant="contained"
                          sx={{
                            width: "40%",
                            borderRadius: '3px', // Add border-radius for curved corners
                            background: theme.palette.button.primary.main,
                            backgroundImage: 'url(/assets/img/ff0613_button_big.png)',
                            padding: 0,
                            fontSize: "12px",
                            backgroundSize: "100% 100%",
                            transition: 'box-shadow 0.4s ease-in-out',
                            '&:hover': {
                              boxShadow: '0 0 20px rgba(238, 40, 66, 0.7)', // Adjust hover shadow color and size as needed
                            },
                            '@keyframes shadow-pulse': {
                              '0%': {
                                boxShadow: '0 0 0 5px rgba(238, 40, 66, 0.7)', // Adjust initial shadow color and size as needed
                              },
                              '80%': {
                                boxShadow: '0 0 5px rgba(238, 40, 66, 0.7)', // Adjust pulse shadow color and size as needed
                              },
                            },
                            '@keyframes vibration': {
                              '0%': {
                                transform: 'translateX(0)',
                              },
                              '25%': {
                                transform: 'translateX(-2px)',
                              },
                              '50%': {
                                transform: 'translateX(2px)',
                              },
                              '75%': {
                                transform: 'translateX(-2px)',
                              },
                              '100%': {
                                transform: 'translateX(0)',
                              },
                            },
                            animation: 'shadow-pulse 1s infinite alternate, vibration 0.7s infinite', // Combine pulse and vibration animations

                          }}
                          disableRipple
                          onClick={() =>
                              navigate(Constant.getPath("/register"))
                          }
                      >
                        {t("home.signup")}
                      </Button>
                      <LanguageDropdown languages={languages?.result}/>

                    </Box>
                  </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </>
  );
};

MobileHeader.propTypes = {
  background: PropTypes.string,
};

MobileHeader.defaultProps = {
  background: "",
};

export default MobileHeader;
